<template>
  <div class="container2">
  <div class="form-group search">
    <label for="formGroupInput">Datenbankeinträge ({{store.numberOfVsnr}}) durchsuchen: </label>
    <input v-model.number="inputVersnr" type="number" class="form-control" id="formGroupInput" placeholder="VSNR" :maxlength="maxLength">
  </div>
  <div class="error" v-if="!searchVersnr">
    <p>{{ searchMessage }}</p>
  </div>
  <div class="listeSuche" v-if="searchVersnr">
    <div class="list vsnr">{{ inputVersnr }}</div><button type="button" class="btn btn-secondary list down one"  @click="downloadList(inputVersnr)"><font-awesome-icon icon="file-download" />Excel 1</button><button type="button" class="btn btn-secondary list down two"  @click="downloadList2(inputVersnr)"><font-awesome-icon icon="file-download" />Excel 2</button><button name="löschen" type="button" class="btn btn-light list del" @click="deleteVersNr(inputVersnr)"><font-awesome-icon style="color: #b61423" icon="times-circle" /></button>
  </div>
  </div>
<div id="listDownload">
  <a class="" data-toggle="collapse" href="#collapseGlobalList" role="button" aria-expanded="false" aria-controls="collapseExample">
    <h5 class="headlineList">Liste der letzten Uploads <font-awesome-icon icon="chevron-down"/></h5>
  </a>
  <div class="collapse" id="collapseGlobalList">
  <ul class="filelist">
    <TransitionGroup name="list" tag="ul">
    <li v-for="liste in store.listDownload" :key="liste">
      <div class="list vsnr">{{ liste }}</div><button type="button" class="btn btn-secondary list down one"  @click="downloadList(liste)"><font-awesome-icon icon="file-download" />Excel 1</button><button type="button" class="btn btn-secondary list down two" @click="downloadList2(liste)"><font-awesome-icon icon="file-download" />Excel 2</button><button name="löschen" type="button" class="btn btn-light list del" @click="deleteVersNr(liste)"><font-awesome-icon style="color: #b61423" icon="times-circle" /></button>
      <div class="trenner"></div>
    </li>
    </TransitionGroup>
  </ul>
    </div>
</div>
</template>

<script>
import {authService} from "@/api";
import {store} from "../store";


export default {
  name: "ListDownload",
  data() {
    return {
      content: "home",
      store,
      inputVersnr: "",
      searchVersnr: false,
      searchMessage: "",
      maxLength: 10,
      color:"red",
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    getList(){
      if(store.debug){
        console.log("listDownload.vue refresh getList()");
      }
      authService.get('/list-simple.php')
          .then(function (response) {
            // handle success

          if(store.debug){
            console.log("listDownload.vue listDownload.vue response liste: " + (store.listDownload));
            console.log("listDownload.vue Object.entries(response.data): " + JSON.stringify(Object.entries(response.data)));
            console.log("listDownload.vue Object.values(response.data): " + JSON.stringify(Object.values(response.data)));
          }
            store.setListDownload(Object.values(response.data));
            store.setNumberOfVsnr(Object.values(response.data).length);
          })
          .catch(function (error) {
            // handle error
            if(store.debug){
              console.log("listDownload.vue error: " + error);
              console.log("listDownload.vue error.response.status: " + error.response.status);
            }
          })
          .then(function () {
            // always executed
          });
     },
    deleteVersNr(vers){
      store.setErrorMsgUpload("");
      store.setCssMsgUpload("neutral");
      if (window.confirm('Soll die VSNR  '+ vers + ' aus der Datenbank gelöscht werden?'))
      {
      store.setIsWaitingForServerRequest(true);
      if(store.debug){
        console.log("listDownload.vue delete: "+vers);
        console.log("listDownload.vue delete url: pva.calcugraph.at/mw/delete.php?vsnr="+vers);
      }

      let self = this;
      authService.get('/delete.php?vsnr='+vers)
          //.then(function (response) {
          .then(function (response) {
            if(store.debug){
              console.log("listDownload.vue delete response: " + response.data);
              console.log("listDownload.vue delete response.status: " + response.status);
            }
            if(response.status === 204){
              if(store.debug){
                console.log("listDownload.vue refresh list after delete***********");
              }
              self.getList();
            }
            store.setIsWaitingForServerRequest(false);
            // clear list in ReadFile******************
            store.clearOneVsnrInActualUploadListVsnr(vers);
            if(store.inputFieldVsnr == vers){
              if(store.debug){
                console.log("listDownload.vue ******** SAME NUMBER *********");
              }
              self.inputVersnr = "";
            }
          }).catch(function (error) {
        // handle error
        if(store.debug){
          console.log("listDownload.vue error.response.statusCode from delete: " + error.response.status);
        }
        store.setIsWaitingForServerRequest(false);
        if(error.response.status === 401){
          if(store.debug){
            console.log("listDownload.vue ******** ERROR 401 IN DELETE CATCH **************");
          }
          store.setIsLoggedIn(false);
        }
      })
          .then(function () {
            // always executed
      });
      }
      else
      {
        if(store.debug){
          console.log("listDownload.vue " + vers + " wird NICHT gelöscht");
        }
      }
    },
    downloadList(vers){
      store.setErrorMsgUpload("");
      store.setCssMsgUpload("neutral");
      store.setIsWaitingForServerRequest(true);
      authService({
        url: '/export-vvp.php?vsnr='+vers,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        if(store.debug){
          console.log("listDownload.vue download file1 status: " + response.status);
        }
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        var fileName = 'vvp2excel_' + vers + '.xlsm'

        fileLink.href = fileURL;
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
        store.setIsWaitingForServerRequest(false);
      }).catch(function (error) {
        // handle error
        if(store.debug){
          console.log("listDownload.vue error download file1: " + error);
          console.log("listDownload.vue error download file1: " + error.response.status);
        }
        store.setIsWaitingForServerRequest(false);
        if(error.response.status === 401){
          if(store.debug){
            console.log("listDownload.vue ******** ERROR 401 IN LIST1 CATCH **************");
          }
          store.setIsLoggedIn(false);
        }
      })
    },
    downloadList2(vers){
      store.setErrorMsgUpload("");
      store.setCssMsgUpload("neutral");
      store.setIsWaitingForServerRequest(true);
      authService({
        url: '/export-sql.php?vsnr='+vers,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        if(store.debug){
          console.log("listDownload.vue download file2 status: " + response.status);
        }
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        var fileName = 'export-sql_' + vers + '.xlsm'
        fileLink.href = fileURL;
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
        store.setIsWaitingForServerRequest(false);
      }).catch(function (error) {
        // handle error
        if(store.debug){
          console.log("listDownload.vue error download file2: " + error);
        }
        //console.log("error download file2: " + error.response.status);
        store.setIsWaitingForServerRequest(false);
        if(error.response.status === 401){
          if(store.debug){
            console.log("listDownload.vue ******** ERROR 401 IN LIST2 CATCH **************");
          }
          store.setIsLoggedIn(false);
        }
      })
    },
  },
  // ToDo call methods over components
  computed:{
    count(){
      if(store.debug){
        console.log("listDownload.vue ******** WATCH Computed **************");
      }
      return store.watchList;
    },
    search(){
      return this.inputVersnr;
    },
  },
  watch:{
    count () {
      if(store.debug){
        console.log("listDownload.vue ******** WATCH getList **************");
      }
      this.getList();
    },
    search(){
      const len = Math.ceil(Math.log10(this.inputVersnr + 1));
      if(store.debug){
        console.log("listDownload.vue ******** WATCH search **************");
        console.log("listDownload.vue versNr: " + this.inputVersnr);
        console.log("listDownload.vue versNr länge: " + len);
      }

      if(len === 10){
        store.setInputFieldVsnr(this.inputVersnr);
        if(store.debug){
          console.log("listDownload.vue" + typeof store.listDownload);
          console.log("listDownload.vue länge "+ store.listDownload.length);
          console.log("listDownload.vue nr.1 der liste "+ store.listDownload[0]);
          console.log("listDownload.vue nr.2 der liste "+ store.listDownload[1]);
          console.log("listDownload.vue nr.3 der liste "+ store.listDownload[2]);
        }

        for(var i=0; i < store.listDownload.length; i++) {
          if(store.listDownload[i] == this.inputVersnr){
            if(store.debug){
              console.log("listDownload.vue Nummer Vorhanden");
              console.log("listDownload.vue Input VersNr: " + this.inputVersnr);
            }
            this.searchVersnr = true;
            return false
          }else{
            if(store.debug){
              console.log("listDownload.vue Nummer NICHT Vorhanden");
              console.log("listDownload.vue Input VSNR: " + this.inputVersnr);
            }
            this.searchMessage = "Die VSNR wurde nicht gefunden.";
            this.searchVersnr = false;
          }
        }
      }else if(len === 0){
        this.searchVersnr = false;
        this.searchMessage = "";
      }else{
        this.searchVersnr = false;
        this.searchMessage = "Bitte geben Sie eine 10 stellige VSNR ein.";
      }
      store.setErrorMsgUpload("");
      store.setCssMsgUpload("neutral");
    }
  }
};
</script>

<style>

</style>
