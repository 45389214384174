<template>
  <div id="app">
    <nav class="navbar navbar-expand navbar-dark bg-dark">
      <!-- <a href="/" class="navbar-brand">AK PVA Tool</a>-->
      <div class="navbar-brand">
        <img src="logo-ak.png">
        <p class="logo-claim">PVA<br>CONVERT</p>
      </div>
      <div class="navbar-nav mr-auto">
<!--        <li class="nav-item">
          <router-link to="/convert" class="nav-link">
            <font-awesome-icon icon="arrows-alt-v" /> Convert
          </router-link>
        </li>-->
        <!-- <li v-if="showAdminBoard" class="nav-item">-->
        <!-- <li class="nav-item">
          <router-link to="/admin" class="nav-link">Admin Board</router-link>
        </li>-->
        <!-- <li v-if="showModeratorBoard" class="nav-item">-->
        <!-- <li class="nav-item">
          <router-link to="/mod" class="nav-link">Moderator Board</router-link>
        </li>-->
        <!-- <li class="nav-item">
            &lt;!&ndash; <router-link v-if="currentUser" to="/user" class="nav-link">User</router-link>&ndash;&gt;
          <router-link v-if="currentUser" to="/user" class="nav-link">User</router-link>
        </li>-->
      </div>

      <div v-if="!store.isLoggedIn" class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link to="/login" class="nav-link">
            <font-awesome-icon icon="sign-in-alt" /> Login
          </router-link>
        </li>
      </div>

      <div v-if="store.isLoggedIn" class="navbar-nav ml-auto">
          <!-- <li class="nav-item"> -->
          <!-- <router-link to="/profile" class="nav-link"> -->
          <a class="nav-link">
            <font-awesome-icon icon="user" />
            {{ store.currentUser }}
          </a>
          <!--</router-link>-->
        <!--</li> -->
        <li class="nav-item">
          <a class="nav-link logout" @click.prevent="logOut">
            <font-awesome-icon icon="sign-out-alt" /> LogOut
          </a>
        </li>
      </div>
    </nav>

    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
import {store} from "@/store";
import router from "@/router";
import {authService} from "@/api";
export default {
  data() {
    return {
      store,
      router
    };
  },
  computed: {
    currentUser() {
      if(store.debug){
        console.log("app.vue computed() / currentUser() ");
      }
      return store.isLoggedIn;
    },
  },
  watch:{
    currentUser() {
      if(store.debug){
        console.log("app.vue watch () /  currentUser()");
      }
      if(store.isLoggedIn === false){
        router.push("/login");
      }
    }
  },
  methods: {
    logOut() {
      if(store.debug){
        console.log("app.vue COOKIE: " + document.cookie);
      }
      document.cookie = "PHPSESSID= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
      store.setIsLoggedIn(false);
      router.push("/login");


      //this.$store.dispatch('auth/logout');
      //this.$router.push('/login');
    }
  },
  mounted() {
    // Make a request
    authService.get('/auth.php')
        .then(function (response) {
          // handle success
          if(store.debug){
            console.log("app.vue response: " + response);
            console.log("app.vue response.status: " + response.status);
          }
          store.setIsLoggedIn(true);
          router.push("/convert");
        })
        .catch(function (error) {
          // handle error
          if(store.debug){
            console.log("app.vue error: " + error);
            console.log("app.vue error.response.status: " + error.response.status);
          }
          store.setIsLoggedIn(false);
        })
        .then(function () {
          // always executed
        });

  },
    //EventBus.on("logout", () => {
      //this.logOut();
    //});
 // },
  beforeUnmount() {
    //EventBus.remove("logout");
  }

};
</script>


<style scoped>
.navbar {
  padding: 0.5rem 2rem;
}
.logo{
  color: white !important;
}
.nav-link.logout{
  cursor: pointer;
}
.navbar-brand{
  display: flex;
  align-items: center;
}
.navbar-brand img{

  width: 40px;
  height: 40px;
}
.navbar-brand .logo-claim{
  padding-left: 10px;
  font-size: 12px;
  line-height: 17px;
  color: #b9b9b9;
}
p.logo-claim {
  margin-bottom: 2px !important;
}
</style>
