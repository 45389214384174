<template>
  <div id="readFile" v-cloak>
    <input class="inputfile" id="file" type="file" ref="myFile" @click="errorMsg" @change="selectedFile" name="files[]" multiple accept=".csv" >
    <label for="file"><font-awesome-icon aria-hidden="true" icon="file-csv"/><span class="csv">CSV-Dateien auswählen</span></label><br/>
    <div id="listDownloadUpl">
      <ul class="filelist">
        <TransitionGroup name="list" tag="ul">
        <li v-for="liste in store.actualUploadListVsnr" :key="liste">
          <div class="list vsnr">{{ liste }}</div><button type="button" class="btn btn-secondary list down one"  @click="downloadList(liste)"><font-awesome-icon icon="file-download" />Excel 1</button><button type="button" class="btn btn-secondary list down two" @click="downloadList2(liste)"><font-awesome-icon icon="file-download" />Excel 2</button><button name="löschen" type="button" class="btn btn-light list del" @click="deleteVersNr(liste)"><font-awesome-icon style="color: #b61423" icon="times-circle" /></button><font-awesome-icon style="color: seagreen" aria-hidden="true" icon="check" />
          <div class="trenner"></div>
        </li>
        </TransitionGroup>
      </ul>
    </div>
    <div :class="store.cssMsgUpload">{{ store.errorMsgUpload }}</div>
    <ul class="filelist choosen-files">
      <li v-for="output in store.fileName" :key="output.name" class="csvList">
        {{ output }}
      </li>
    </ul>
    <button class="btn btn-danger" v-if="store.readyForUpload" type="button" @click="uploadFiles">
      <font-awesome-icon aria-hidden="true" icon="file-upload"/>
      CSV-Dateien konvertieren
    </button>
    <!--    <textarea v-model="text"></textarea>-->
  </div>

</template>

<script>
import {store} from '../store.js';
import {authService} from "@/api";

export default {
  /*setup() {
    return {
      text: "",
      color: "red",
      store
    }

  },*/
  name: "ReadFile",
  data() {
    return {
      text: "",
      color: "red",
      store
    }
  },
  methods: {
    errorMsg(){
      store.setErrorMsgUpload("");
      store.setCssMsgUpload("neutral");
      //store.clearActualUploadListVsnr();
      this.$refs.myFile.value=null;
    },
    downloadList(vsnr){
      this.$emit('download1_event',vsnr);
    },
    downloadList2(vsnr){
      this.$emit('download2_event',vsnr);
    },
    deleteVersNr(vsnr){
      this.$emit('delete_event',vsnr);
    },
    selectedFile() {
      // Check for the various File API support. *********** Test start
      if (window.File && window.FileReader && window.FileList && window.Blob) {
        // Great success! All the File APIs are supported.
      } else {
        alert('The File APIs are not fully supported in this browser.');
      }
      if(store.debug){
        console.log('ReadFile.vue Anzahl der Files: ' + this.$refs.myFile.files.length);
      }

      if (this.$refs.myFile.files.length === 6) {
        // comment
      } else {
        alert('Bitte markieren Sie alle 6 CSV-Dateien.');
        return false;
      }
      for (let i = 0; i < this.$refs.myFile.files.length; i++) {
        if(store.debug){
          console.log("ReadFile.vue files[i]: " + this.$refs.myFile.files[i]);
          console.log("ReadFile.vue name: " + this.$refs.myFile.files[i].name);
        }
        store.setFileName(i, this.$refs.myFile.files[i].name);
        store.setCsvFile(i, this.$refs.myFile.files[i]);

        //ToDo
        //if (!store.csvFile[i] || store.csvFile[i].type !== 'text/csv') return;

        let reader = new FileReader();
        reader.readAsText(store.csvFile[i], "UTF-8");
        reader.onload = evt => {
          this.text = evt.target.result;
          store.setCsvFileData(i, this.text);
          //***************

          //ToDo: push;
          let csvFileName = this.$refs.myFile.files[i].name;
          // eslint-disable-next-line no-unused-vars
          let j = 0;
          if (csvFileName.includes("APG")) {
            j = 0;
            if(store.debug){
              console.log("ReadFile.vue:: _AGP_ ok");
              console.log("ReadFile.vue:: j: " + j);
            }
            store.setFilesToSend_APG(store.csvFileData[i]);
          } else if (csvFileName.includes("DG")) {
            j = 1;
            if(store.debug){
              console.log("ReadFile.vue:: _DG_ ok");
              console.log("ReadFile.vue:: j: " + j);
            }
            store.setFilesToSend_DG(store.csvFileData[i]);
          } else if (csvFileName.includes("LEIST")) {
            j = 2;
            if(store.debug){
              console.log("ReadFile.vue:: _LEIST_ ok");
              console.log("ReadFile.vue:: j: " + j);
            }
            store.setFilesToSend_LEIST(store.csvFileData[i]);
          } else if (csvFileName.includes("PERS")) {
            j = 3;
            if(store.debug){
              console.log("ReadFile.vue:: _PERS_ ok");
              console.log("ReadFile.vue:: j: " + j);
            }
            store.setFilesToSend_PERS(store.csvFileData[i]);
          } else if (csvFileName.includes("VK")) {
            j = 4;
            if(store.debug){
              console.log("ReadFile.vue:: _VK_ ok");
              console.log("ReadFile.vue:: j: " + j);
            }
            store.setFilesToSend_VK(store.csvFileData[i]);
          } else if (csvFileName.includes("VM")) {
            j = 5;
            if(store.debug){
              console.log("ReadFile.vue:: _VM_ ok");
              console.log("ReadFile.vue:: j: " + j);
            }
            store.setFilesToSend_VM(store.csvFileData[i]);
          } else {
            //FEHLER BEIM EINLESEN
            if(store.debug){
              console.log("ReadFile.vue:: ok");
              console.log("ReadFile.vue:: j: " + j);
            }

          }
        }
        // If we use onloadend, we need to check the readyState.
        reader.onloadend = function (evt) {

          if (evt.target.readyState == FileReader.DONE) { // DONE == 2
            //document.getElementById('byte_content').textContent = evt.target.result;  // beispiel
            store.setReadyForUpload(true);
            if(store.debug){
              console.log("ReadFile.vue:: LOAD END OK: " + store.readyForUpload);
            }
          } else {
            store.setReadyForUpload(false);
            alert('The File could not be loaded.');
          }
        };
        if(store.debug){
          console.log("ReadFile.vue:: Runde: " + i);
        }

      } // end for
      if(store.debug){
        console.log("ReadFile.vue:: Anzahl csv contents: " + store.csvFileData.length);
      }
      store.setReadyForUpload(true);
    },
    uploadFiles() {
      store.setIsWaitingForServerRequest(true);
      let filesToSendStrg = JSON.stringify(store.filesToSend); // new store
      //console.log("store.filesToSend: " + filesToSendStrg);
      authService.post('/upload.php', filesToSendStrg)
          .then(function (response) {
            //ToDo: get code from BE
            store.setActualUploadList(response.data);
            if(store.debug){
              console.log("ReadFile.vue:: store.filesToSend: " + JSON.stringify(store.filesToSend));
              console.log("ReadFile.vue:: response.status: " + JSON.stringify(response.status));
              console.log("ReadFile.vue:: answer mw: " + JSON.stringify(store.actualUploadList));
              console.log("ReadFile.vue:: answer length mw: " + store.actualUploadList.length);
              console.log("ReadFile.vue:: answer vsnr mw: " + store.actualUploadList[0]['vsnr']);
              console.log("ReadFile.vue:: answer Object vsnr: " + JSON.stringify(Object.values(response.data)));
            }
            store.clearActualUploadListVsnr();
            for(var i=0; i <  store.actualUploadList.length; i++) {
              store.setActualUploadListVsnr(store.actualUploadList[i]['vsnr']);
            }
            if(store.debug){
              console.log("ReadFile.vue:: answer NEW List: " + store.actualUploadListVsnr);
            }

            if(response.status === 200){
              if(store.debug){
                console.log("ReadFile.vue:: refresh list after upload*********** status 200");
              }

            }
            // hide button & list
            store.setReadyForUpload(false);
            store.clearFileName();
            store.setErrorMsgUpload("Upload erfolgreich ");
            store.setCssMsgUpload("successMsg");
            store.setIsWaitingForServerRequest(false);
          })
          .catch(function (error) {

            // ToDo: own error if duplicate entries
            //console.log("error.response.status: " + error.response.status);
            //console.log("error.response.data: " + error.response.data);
            if(error.response.status === 401){
              store.setIsLoggedIn(false);
            }
            // hide button & list
            store.setReadyForUpload(false);
            store.clearFileName();
            store.setErrorMsgUpload("beim Upload ist ein Fehler passiert.");
            store.setCssMsgUpload("errorMsg");
            store.setIsWaitingForServerRequest(false);
          })
          //.then(function () {
          .then(() => {
            //console.log("var before set: " + store.watchList);
            store.setWatchList(1);
            //console.log("var after set: " + store.watchList);
          });

    }
  }
};
</script>

<style scoped>

</style>
