import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome,
  faPlus,
  faFileUpload,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faTrashAlt,
  faBan,
  faFileDownload,
  faArrowsAltV,
  faFileCsv,
  faChevronDown,
  faTrashRestoreAlt,
  faCheck,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

library.add(faHome, faPlus, faFileUpload, faUser, faUserPlus, faSignInAlt, faSignOutAlt,faTrashAlt,faBan,faFileDownload,faArrowsAltV,faFileCsv,faChevronDown,faTrashRestoreAlt,faCheck,faTimesCircle);

export { FontAwesomeIcon };
