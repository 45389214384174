<template>
  <div class="col-md-12">
    <div class="card card-container">
      <img
          id="profile-img"
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          class="profile-img-card"
      />
      <Form @submit="handleLogin" :validation-schema="schema">
        <div class="form-group">
          <label for="login">Benutzername</label>
          <Field name="login" type="text" class="form-control user"/>
          <ErrorMessage name="login" class="error-feedback user"/>
        </div>
        <div class="form-group">
          <label for="password">Passwort</label>
          <Field name="password" type="password" class="form-control pass"/>
          <ErrorMessage name="password" class="error-feedback pass"/>
        </div>

        <div class="form-group">
          <button class="btn btn-primary btn-block login" :disabled="loading">
            <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
            ></span>
            <span>Login</span>
          </button>
        </div>

        <div class="form-group">
          <div v-if="store.errorMessage" class="alert alert-danger" role="alert">
            {{ store.errorMessage }}
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import {authService} from "@/api";
import {store} from '../store.js'
import {Form, Field, ErrorMessage} from "vee-validate";
import * as yup from "yup";
import router from "@/router";


export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      login: yup.string().required("Benutzername eingeben!"),
      password: yup.string().required("Passwort eingeben!"),
    });

    return {
      loading: false,
      message: "",
      schema,
      store
    };
  },
  computed: {
    /*loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },*/
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/convert");
    }
  },
  methods: {
    handleLogin(user) {
      const auth = JSON.stringify(user);
      const headers = {
        "content-type": "application/json",
        //'Access-Control-Allow-Origin': '*',
      };
      if(store.debug){
        console.log("login.vue JSON.stringify(user): " + auth);
        console.log("login.vue user.login: " + user.login);
      }
      authService.post("/login.php", auth, {headers})
          .then(function (response) {
            // Erfolg verarbeiten
            if(store.debug){
              console.log("login.vue response.data: " + JSON.stringify(response.data));
              console.log("login.vue response.headers: " + JSON.stringify(response.headers));
              console.log("login.vue response.status: " + JSON.stringify(response.status));
              console.log("login.vue response.request: " + JSON.stringify(response.request));
            }
            store.setErrorMessage("");
            store.setCurrentUser(user.login);
            store.setIsLoggedIn(true);
            router.push("/convert");
          })
          .catch(function (error) {
            // Fehler verarbeiten
            if(store.debug){
              console.log("login.vue error: " + error);
              console.log("login.vue error.response.status: " + error.response.status);
              console.log("login.vue error.response.data: " + error.response.data);
              console.log("login.vue error.response.headers: " + error.response.headers);
              console.log("login.vue error.response.request: " + error.response.request);
            }

            //store.setErrorMessage("error.response.status: " + error.response.status);
            store.setErrorMessage("falscher Benutzer/Passwort ");
            store.setCurrentUser("");
          })
          .then(function () {
            // Wird immer ausgeführt
          });
      if(store.debug){
        console.log("login.vue sessionID: " + store.sessionID);
      }
    }
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.error-feedback {
  color: red;
}
</style>
