<template>
  <div class="container">
    <header class="jumbotron">
      <a class="" data-toggle="collapse" href="#collapseInfo" role="button" aria-expanded="false" aria-controls="collapseExample">
      <h5>Anleitung <font-awesome-icon icon="chevron-down"/></h5>
      </a>
      <div class="collapse" id="collapseInfo">
      <p><b><br>DATEN KONVERTIEREN</b><br><br>1. Schritt:<br>Bitte wählen Sie die 6 zusammengehörigen CSV-Dateien.<br>
      Achten Sie darauf, dass die Dateinamen folgende Bezeichnungen enthalten:</p>
      <span class="hervor first"><b>APG</b></span> z.B.: VVP_<span class="hervor"><b>APG</b></span>_JJJJMMTT.csv<br>
      <span class="hervor first"><b>DG</b></span> z.B.: VVP_<span class="hervor"><b>DG</b></span>_JJJJMMTT.csv<br>
      <span class="hervor first"><b>LEIST</b></span> z.B.: VVP_<span class="hervor"><b>LEIST</b></span>_JJJJMMTT.csv<br>
      <span class="hervor first"><b>PERS</b></span> z.B.: VVP_<span class="hervor"><b>PERS</b></span>_JJJJMMTT.csv<br>
      <span class="hervor first"><b>VK</b></span> z.B.: VVP_<span class="hervor"><b>VK</b></span>_JJJJMMTT.csv<br>
      <span class="hervor first"><b>VM</b></span> z.B.: VVP_<span class="hervor"><b>VM</b></span>_JJJJMMTT.csv<br><br>
      <p>2. Schritt:<br>Klicken Sie auf "<b>CSV-Dateien auswählen"</b>. Es öffnet sich das Kontextmenü ihres Computers,<br>öffnen Sie den Ordner, in dem Sie Ihre Dateien abgespeichert haben und markieren Sie die 6 CSV-Dateien gleichzeitig.<br> Klicken Sie auf <b>"Öffnen"</b>. </p>
      <p>3. Schritt:<br>Sobald Sie die CVS-Dateien hochgeladen haben, klicken Sie auf "<b>CSV-Dateien konvertieren</b>".<br>Nach erfolgreicher Verarbeitung werden Ihnen 2 Excel-Files zum Herunterladen angezeigt.<br><b>Excel 1</b> (vvp2excel.xlsm) / <b>Excel 2</b> (export-sql.xlsm)</p>
      <p><br><b>SUCHEN NACH VSNR.:</b><br><br>Geben Sie im Suchfeld die gewünscshte VSNR. ein. Alle bisher konvertierten CSV-Dateien werden durchsucht.<br> Sobald Sie eine VSNR. ausgewählt haben werden Ihnen unterhalb des Suchfeldes 2 Excel-Files zum Herunterladen angezeigt.</p>
      <p><br><b>LISTE DER LETZTEN UPLOADS:</b><br><br> Hier finden Sie die letzten 20 Uploads aller Benutzer (Zahl noch zu definieren)</p>

      </div>
    </header>
    <!--<FileUpload></FileUpload>-->
    <p></p>
    <ReadFile
        @download1_event="downloadList_1"
        @download2_event="downloadList_2"
        @delete_event="delete_vsnr">
    </ReadFile>
    <ListDownload
        ref="myFunction">
    </ListDownload>
  </div>
  <div id="modalLoading" v-if="store.isWaitingforServerRequest">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

</template>

<script>
import ReadFile from "@/components/ReadFile";
import ListDownload from "./ListDownload";
import {authService} from "@/api";
import {store} from '../store.js';
import router from "@/router";



export default {
  name: "Home",
  components: {ReadFile, ListDownload},
  data() {
    return {
      content: "home",
      store,
      router
    };
  },
  mounted() {
    // Make a request
    authService.get('/auth.php')
        .then(function (response) {
          // handle success
          if(store.debug){
            console.log("home.vue response.status home: " + response.status);
          }
          store.setIsLoggedIn(true);
        })
        .catch(function (error) {
          // handle error
              if(store.debug){
                console.log("home.vue error: " + error);
                console.log("home.vue error.response.status from home: " + error.response.status);
              }
          store.setIsLoggedIn(false);
        })
        .then(function () {
          // always executed
        });


  },
  methods: {
    downloadList_1(vsnr){
      if(store.debug){
        console.log("home.vue downloadList_1: "+vsnr);
      }
      this.$refs.myFunction.downloadList(vsnr);
    },
    downloadList_2(vsnr){
      if(store.debug){
        console.log("home.vue downloadList_2: "+vsnr);
      }
      this.$refs.myFunction.downloadList2(vsnr);
    },
    delete_vsnr(vsnr){
      if(store.debug){
        console.log("home.vue delete_vsnr(vsnr): "+vsnr);
      }
      this.$refs.myFunction.deleteVersNr(vsnr);
    },
  }
};

</script>

<style>
.jumbotron a,#listDownload a  {
  color: #b61423 !important;
}
.jumbotron a:hover,#listDownload a  {
  color: #b61423 !important;
}
/*original css home*/
.hervor{color: #b61423;}
.hervor.first{width: 30px;}
.jumbotron {padding: 2rem 2rem !important;}
.modal-content{width: 70px;background-color: gray;}
#modalLoading{
  position: fixed;
  top: 50%;
  left: 50%;
}

/*original css ReadFile*/
input{
  /*  z-index: 10;
    background-color: yellowgreen !important;*/
}
input[type="file" i] {
  /*background-color: gray;*/
  color: black;
  border:none;

  /*  appearance: none;
    cursor: default;
    align-items: baseline;
    color: inherit;
    text-overflow: ellipsis;
    white-space: pre;
    text-align: start !important;
    padding: initial;
    border: initial;
    overflow: hidden !important;*/
}
ul.filelist{
  padding-left:5px;
  margin-top:20px;
  list-style: none;
}
ul.filelist ul {
  padding-left: 0;
}


.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 400;
  color: white;
  background-color: #424D59FF;
  display: inline-block;
  cursor: pointer; /* "hand" cursor */
  border-radius: 0.25rem;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #424D59FF;
}
.inputfile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.csv{
  margin-left:5px;
}
.errorMsg,.successMsg,.neutral{
  /*height: 30px;*/
}
.errorMsg{
  color: #b61423;
}
.successMsg{
  color: forestgreen;
}
.btn.btn-success,.btn.btn-danger{
  margin-bottom: 20px;
}
#listDownloadUpl li button {
  padding: 2px;
}
svg.svg-inline--fa {
  margin-top: 4px;
}
/*original css ListDownload*/
.headlineList{
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 0;
}
ul.filelist{
  padding-left: 5px;
  margin-top:20px;
  list-style: none;
}
ul li, .listeSuche{
  display: flex;
  height: 36px;
}
.liste.suche .list.vsnr{
  height: 36px;

}
ul li{
  margin-bottom: 2px;
}
.list{
  margin-right: 10px;
}
.list.del{
  color: white;
  cursor: pointer;
}
.list.del:hover{
  text-decoration: underline;
}
.list.down.one{
  cursor: pointer;
}
.list.down.one:hover,.list.down.two:hover{
  text-decoration: underline;
}
.list.down svg{
  margin-right: 5px;
}
.list.vsnr{
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.listeSuche{
  height: 60px;
  padding-left: 20px;
  padding-bottom: 20px;

}
.listeSuche button,ul li button, #listDownload li button, .listDownloadUpl li button, #listDownloadUpl ul li button{
  padding: 2px 12px;
}
.error{
  height: 24px;
  color: #b61423;
  padding-left: 20px;
}
.form-group.search{
  max-width: 320px;
}
button.list.down.one{
  background-color: #969494;
}
button.list.down.two{
  background-color: #b9b9b9;
}
.btn.btn-danger{
  background-color: #b61423;
}
.filelist.choosen-files li{
height: 24px;
}
#listDownload{
  margin: 30px 0 40px 0;
  padding: 2px 10px 20px 20px;
  background-color: #e9ecef;
}
#readFile, form-group.search, .container2 {
  background-color: #e9ecef !important;
}
#readFile{
  margin-bottom: 0;
  padding: 20px;
}
.form-group.search{
  margin-top: 0;
  padding: 20px 20px 0 20px;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
@media only screen and (max-width: 490px) {
  ul li, .listeSuche {
    display: block;
    height: 60px;
  }
  @media only screen and (max-width: 390px) {
    ul li, .listeSuche {
      display: block;
      height: 90px;
    }
  }
}

</style>
