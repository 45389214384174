import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import {store} from "@/store";
//import Register from "./components/Register.vue";
// lazy-loaded
//const Profile = () => import("./components/Profile.vue")
//const BoardAdmin = () => import("./components/BoardAdmin.vue")
//const BoardModerator = () => import("./components/BoardModerator.vue")
//const BoardUser = () => import("./components/BoardUser.vue")

const routes = [
  {
    path: "/",
    name: "convert",
    component: Home,
  },
  {
    path: "/convert",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
/*  {
    path: "/register",
    component: Register,
  },*/
 /* {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },*/
  /*{
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },*/
  /*{
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: BoardModerator,
  },*/
  /*{
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },*/
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
   //const publicPages = ['/login', '/register', '/home'];
   const publicPages = ['/login'];
   const authRequired = !publicPages.includes(to.path);
   const loggedIn = store.isLoggedIn;

   // trying to access a restricted page + not logged in
   // redirect to login page
   if (authRequired && !loggedIn) {
     next('/login');
   } else {
     next();
   }
 });

export default router;
