import axios from 'axios';
import {store} from "@/store";


const authService = axios.create({
    // production ******************** production
    baseURL: store.baseUrlServer,
    withCredentials: true,

    // localhost ********************* localhost
    /*baseURL: store.baseUrlLocal,
    withCredentials: true,*/

});
export { authService };
