import {reactive} from 'vue'
//import axios from "axios";

export const store = reactive({
    debug: false,
    errorMessage: '',
    filesToSend:[
        {APG: ''},
        {DG: ''},
        {LEIST: ''},
        {PERS: ''},
        {VK: ''},
        {VM: ''}
    ],
    sessionID: "",
    readyForUpload: false,
    fileName: [],
    csvFile: [],
    csvFileData: [],
    isLoggedIn: false,
    baseUrlLocal: "http://localhost:8080/mw", //proxy devServer
    baseUrlServer: "https://pva.calcugraph.at/mw",
    login: "",
    password: "",
    currentUser:"",
    actualUploadList:"",
    actualUploadListVsnr:[],
    listDownload: [],
    listKeyValue: {},
    watchList:1,
    errorMsgUpload:"",
    inputFieldVsnr:"",
    numberOfVsnr: "",
    isWaitingforServerRequest: false,
    cssMsgUpload: 'errorMsg',


    setMessageAction(newValue) {
        if (this.debug) {
            if(this.debug){
                console.log('store.js setMessageAction triggered with', newValue)
            }
        }
        this.message = newValue
    },

    clearSomeValues() {
        if (this.debug) {
            if(this.debug){
                console.log('store.js clearSomeValues triggered')
            }

        }
        this.message_input = '';
        this.count = 0;
    },
    setCountAction() {
        this.count += 1;
    },
    setDebug(newValue){
        this.debug = newValue;
    },
    setFilesToSend_APG(csv){
        this.filesToSend[0].APG = csv;
    },
    setFilesToSend_DG(csv){
        this.filesToSend[1].DG = csv;
    },
    setFilesToSend_LEIST(csv){
        this.filesToSend[2].LEIST = csv;
    },
    setFilesToSend_PERS(csv){
        this.filesToSend[3].PERS = csv;
    },
    setFilesToSend_VK(csv){
        this.filesToSend[4].VK = csv;
    },
    setFilesToSend_VM(csv){
        this.filesToSend[5].VM = csv;
    },
    // sessionID
    setSessionID(newValue) {
        this.sessionID = newValue;
    },
    // readyForUpload
    setReadyForUpload(newValue) {
        if(this.debug){
            //console.log("store.js 1) FROM STORE - newValue: " + newValue);
        }
        this.readyForUpload = newValue;
        if(this.debug){
            //console.log("store.js 2) FROM STORE - readyForUpload: " + this.readyForUpload);
        }
    },
    setFileName(i, name){
        this.fileName[i] = name;
    },
    clearFileName(){
        this.fileName.length=0;
    },
    setCsvFile(i, newValue){
       this.csvFile[i] = newValue;
    },
    setCsvFileData(i, newValue){
        this.csvFileData[i] = newValue;
    },
    setErrorMessage(newValue){
        this.errorMessage = newValue;
    },
    setCurrentUser(newValue){
        this.currentUser = newValue;
    },
    setIsLoggedIn(newValue){
        this.isLoggedIn = newValue;
    },
    setActualUploadList(newValue){
        this.actualUploadList = newValue;
    },
    setActualUploadListVsnr(newValue){
        this.actualUploadListVsnr.push(newValue);
    },
    clearActualUploadListVsnr(){
        this.actualUploadListVsnr.length = 0;
    },
    clearOneVsnrInActualUploadListVsnr(vers){
        if(this.debug){
            console.log("store.js CLEAR IN STORE ARRAY: " + this.actualUploadListVsnr);
            console.log("store.js CLEAR IN STORE ARRAY.lengh: " + this.actualUploadListVsnr.length);
            console.log("store.js CLEAR IN STORE");
            console.log("store.js CLEAR IN STORE VSNR: " + vers);
        }

        for( var i = 0; i < this.actualUploadListVsnr.length; i++){
            if(this.debug){
                console.log("store.js INSIDE FOR LOOP [i]: " + i);
                console.log("store.js INSIDE FOR LOOP this.actualUploadListVsnr[i]: " + this.actualUploadListVsnr[i]);
                console.log("store.js INSIDE FOR LOOP vers: " + vers);
            }

            // input field generates numbers, other versnr are strings -> so dont use '==='
            if ( this.actualUploadListVsnr[i] == vers) {
                if(this.debug){
                    console.log("store.js CLEAR IN STORE - FOUND A DOUBLE: i: " + i + " vers: " + vers);
                }
                this.actualUploadListVsnr.splice(i, 1);
            }
        }
    },
    setListDownload(newValue){
        this.listDownload = newValue;
    },
    setWatchList(newValue){
        this.watchList = this.watchList + newValue;
    },
    setErrorMsgUpload(newValue){
        this.errorMsgUpload = newValue;
    },
    setNumberOfVsnr(newValue){
        this.numberOfVsnr = newValue;
    },
    setIsWaitingForServerRequest(newValue){
        this.isWaitingforServerRequest = newValue;
    },
    setCssMsgUpload(newValue){
        this.cssMsgUpload = newValue;
    },
    setInputFieldVsnr(newValue){
        this.inputFieldVsnr = newValue;
    }
})
